import { v4 as uuidv4 } from 'uuid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getConfig } from 'src/utils/configuracoesSistema';

import axios from 'src/utils/axios';
import { AppThunk } from 'src/store';
import {
  IEtiquetaTable,
  IOrdemProducao,
  IParametrizacaoCentroArmazenagem,
  IParametrizacaoOcorrenciaProducao,
  IParametrizacaoOptions,
  IParametrizacaoTipoOperacao,
} from '../types/ordemProducao';
import {
  OrdemProducaoDTO,
  RecursoProducaoDTO,
} from '../types/dtos/ordemProducao';

interface OrdemProducaoState {
  user: string;
  step: {
    initial: number;
    current: number;
    limit: number;
  };
  opList: OrdemProducaoDTO[];
  activeOp: 0;
  op: IOrdemProducao;
  validationFunction: () => boolean | Promise<boolean>;
  tabFunction: (tab: string) => void | Promise<void>;
  modal: {
    show: boolean;
    message: string;
  };
}

const initialState: OrdemProducaoState = {
  user: '',
  step: {
    initial: getConfig(3096) ? 1 : 2,
    current: getConfig(3096) ? 1 : 2,
    limit: 3,
  },
  opList: [],
  activeOp: 0,
  op: {
    opId: 0,
    talao: 0,
    hasQuiz: false,
    hasDemands: false,
    liberationCode: '',
    sequence: 0,
    validBatch: false,
    initialFinalReading: false,
    changeFabricationDate: true,
    SequenciaPedido: 0,
    fulfilled: {
      found: false,
      message: '',
    },
    material: {
      code: '',
      description: '',
      fullDescription: '',
      unitMeasurement: '',
      image: '',
      integer: 0,
      decimal: 0,
      codigoConfigurador: '',
      codigoEspecificacao1: '',
      codigoEspecificacao2: '',
      codigoEspecificacao3: '',
      utilizaGrade: '',
      controlaNumeroSerie: false,
    },
    ultimaEtapa: false,
    codigoUnidadeNegocio: '',
    tabs: {
      general: {
        quantities: {
          produced: 0,
          defect: 0,
          lost: 0,
          fulfilled: false,
        },
        details: {
          initial: new Date(),
          final: new Date(),
          batch: '',
          fabrication: new Date(),
          valid: new Date(),
        },
        total: 0,
        manufacturedQuantity: 0,
      },
      parametrizacao: {
        tipoOperacao: {
          exit: '',
          fabrication: '',
          defect: '',
          lost: '',
        },
        centroArmazenagem: {
          exit: '',
          fabrication: '',
          defect: '',
          lost: '',
          tecnologiaGrupo: 0,
        },
        ocorrenciasProducao: {
          fabrication: '',
          defect: '',
          solution: '',
        },
        options: {
          confirmQuantities: !!getConfig(404),
        },
      },
      inspection: {},
      recursos: [],
      etiquetas: {
        etiquetasForm: {
          allQuantities: 0,
          activeSelect: {
            title: 'Nº de etiquetas',
            value: 'numbersEtiqueta',
          },
          select: [
            {
              title: 'Nº de etiquetas',
              value: 'numbersEtiqueta',
            },
            {
              title: 'Qtde por Etiquetas',
              value: 'qntEtiqueta',
            },
          ],
          qntValueSelect: 0,
        },
        etiquetasTable: [],
      },
    },
    demandsComplete: false,
    taloes: [],
    leituraPor: 0,
    hasGrid: '',
    numeration: '',
    lote: '',
    carregouDados: false,
    mascaraLote: '',
  },
  validationFunction: () => true,
  tabFunction: () => {},
  modal: {
    show: false,
    message: '',
  },
};

const slice = createSlice({
  name: 'ordemProducao',
  initialState,
  reducers: {
    updateUser(state: OrdemProducaoState, action: PayloadAction<string>): void {
      const user = action.payload;

      state.user = user;
    },
    updateCodeOp(
      state: OrdemProducaoState,
      action: PayloadAction<number>
    ): void {
      const codeOp = action.payload;

      state.op.opId = codeOp;
    },
    updateTalao(
      state: OrdemProducaoState,
      action: PayloadAction<number>
    ): void {
      const talao = action.payload;

      state.op.talao = talao;
    },
    updateQuantity(
      state: OrdemProducaoState,
      action: PayloadAction<{
        key: 'produced' | 'defect' | 'lost';
        quantity: number;
      }>
    ): void {
      const { key, quantity } = action.payload;

      state.op.tabs.general.quantities[key] =
        quantity <= 0 ? 0 : Number(quantity);
    },
    updateDetail(
      state: OrdemProducaoState,
      action: PayloadAction<{
        key: 'initial' | 'final' | 'batch' | 'fabrication' | 'valid';
        value: Date | string | null;
      }>
    ): void {
      const { key, value } = action.payload;

      // @ts-ignore
      state.op.tabs.general.details[key] = value;
    },
    updateAllQuantities(
      state: OrdemProducaoState,
      action: PayloadAction<number>
    ): void {
      const quantity = action.payload;

      state.op.tabs.etiquetas.etiquetasForm.allQuantities = Math.abs(quantity);
    },
    updateEtiquetasSelect(
      state: OrdemProducaoState,
      action: PayloadAction<string>
    ): void {
      const value = action.payload;
      const select = initialState.op.tabs.etiquetas.etiquetasForm.select.find(
        element => element.value === value
      );

      state.op.tabs.etiquetas.etiquetasForm.activeSelect.value = value;
      state.op.tabs.etiquetas.etiquetasForm.activeSelect.title =
        select?.title || '';
    },
    updateQuantitySelect(
      state: OrdemProducaoState,
      action: PayloadAction<number>
    ): void {
      const quantity = action.payload;

      state.op.tabs.etiquetas.etiquetasForm.qntValueSelect = Math.floor(
        Math.abs(quantity)
      );
    },
    updateParametrizacao(
      state: OrdemProducaoState,
      action: PayloadAction<{
        parametrizacao: string;
        name: string;
        value: string | number | boolean | null;
      }>
    ): void {
      const { parametrizacao, name, value } = action.payload;

      // @ts-ignore
      state.op.tabs.parametrizacao[parametrizacao][name] = value;
    },
    updateParametrizacaoConfig(
      state: OrdemProducaoState,
      action: PayloadAction<{
        tipoOperacao: IParametrizacaoTipoOperacao;
        centroArmazenagem: IParametrizacaoCentroArmazenagem;
        ocorrenciasProducao: IParametrizacaoOcorrenciaProducao;
        options: IParametrizacaoOptions;
      }>
    ): void {
      const { tipoOperacao, centroArmazenagem, ocorrenciasProducao, options } =
        action.payload;

      state.op.tabs.parametrizacao = {
        tipoOperacao,
        centroArmazenagem,
        ocorrenciasProducao,
        options,
      };
    },
    updateStep(state: OrdemProducaoState, action: PayloadAction<number>): void {
      const step = action.payload;

      state.step.current = step;
    },
    updateInitialStep(
      state: OrdemProducaoState,
      action: PayloadAction<number>
    ): void {
      const step = action.payload;

      state.step.initial = step;
      state.step.current = step;
    },
    updateStepLimit(
      state: OrdemProducaoState,
      action: PayloadAction<number>
    ): void {
      const limit = action.payload;

      state.step.limit = limit;
    },
    updateRecursos(
      state: OrdemProducaoState,
      action: PayloadAction<RecursoProducaoDTO[]>
    ): void {
      const recursos = action.payload;

      state.op.tabs.recursos = recursos;
    },

    updateDemandsComplete(
      state: OrdemProducaoState,
      action: PayloadAction<boolean>
    ): void {
      const status = action.payload;

      state.op.demandsComplete = status;
    },
    updateExpirationDate(
      state: OrdemProducaoState,
      action: PayloadAction<Date>
    ): void {
      const date = action.payload;

      state.op.tabs.general.details.valid = new Date(date);
    },
    updateFulfilled(
      state: OrdemProducaoState,
      action: PayloadAction<boolean>
    ): void {
      const fulfilled = action.payload;

      state.op.tabs.general.quantities.fulfilled = fulfilled;
    },
    updateModalShow(
      state: OrdemProducaoState,
      action: PayloadAction<boolean>
    ): void {
      const step = action.payload;

      state.modal.show = step;
    },
    updateModalMessage(
      state: OrdemProducaoState,
      action: PayloadAction<string>
    ): void {
      const step = action.payload;

      state.modal.message = step;
    },
    addInfoTable(
      state: OrdemProducaoState,
      action: PayloadAction<{
        quantities: number;
        total: number;
        option: string;
      }>
    ): void {
      const { quantities, total, option } = action.payload;
      const etiquetasTable: IEtiquetaTable[] = [];

      switch (option) {
        case 'numbersEtiqueta':
          if (total % quantities === 0) {
            etiquetasTable.push({
              id: uuidv4(),
              numbersEtiqueta: quantities,
              qntEtiqueta: total / quantities,
            });
          } else {
            if (Math.floor(total / quantities) >= 1) {
              etiquetasTable.push({
                id: uuidv4(),
                numbersEtiqueta: quantities - 1,
                qntEtiqueta: Math.floor(total / quantities),
              });
            }
            if (
              total - Math.floor(total / quantities) * (quantities - 1) >=
              1
            ) {
              etiquetasTable.push({
                id: uuidv4(),
                numbersEtiqueta: 1,
                qntEtiqueta:
                  total - Math.floor(total / quantities) * (quantities - 1),
              });
            }
          }
          break;

        default:
          if (total % quantities === 0) {
            etiquetasTable.push({
              id: uuidv4(),
              numbersEtiqueta: total / quantities,
              qntEtiqueta: quantities,
            });
          } else {
            if (Math.floor(total / quantities) >= 1) {
              etiquetasTable.push({
                id: uuidv4(),
                numbersEtiqueta: Math.floor(total / quantities),
                qntEtiqueta: quantities,
              });
            }
            if (Math.floor(total % quantities) >= 1) {
              etiquetasTable.push({
                id: uuidv4(),
                numbersEtiqueta: 1,
                qntEtiqueta: Math.floor(total % quantities),
              });
            }
          }
          break;
      }

      state.op.tabs.etiquetas.etiquetasTable = etiquetasTable;
    },
    deleteRecurso(
      state: OrdemProducaoState,
      action: PayloadAction<number>
    ): void {
      const index = action.payload;

      state.op.tabs.recursos.splice(index, 1);
    },
    cancel() {
      return initialState;
    },
    selectOrdemProducao(
      state: OrdemProducaoState,
      action: PayloadAction<{
        codigoOrdemProducao: number;
        talao: number;
        sequence: number;
        liberationCode: string;
        total: number;
        manufacturedQuantity: number;
        materialCode: string;
        leituraPor: number;
      }>
    ): void {
      const {
        codigoOrdemProducao,
        talao,
        sequence,
        liberationCode,
        total,
        manufacturedQuantity,
        materialCode,
        leituraPor,
      } = action.payload;

      state.op.opId = codigoOrdemProducao;
      state.op.talao = talao;
      state.op.sequence = sequence;
      state.op.liberationCode = liberationCode;
      state.op.tabs.general.total = total;
      state.op.tabs.general.manufacturedQuantity = manufacturedQuantity;
      state.op.tabs.general.quantities.produced = total - manufacturedQuantity;
      state.op.material.code = materialCode;
      state.op.leituraPor = leituraPor;
    },
    setValidationFunction(
      state: OrdemProducaoState,
      action: PayloadAction<() => boolean | Promise<boolean>>
    ): void {
      const validation = action.payload;

      state.validationFunction = validation;
    },
    setTabFunction(
      state: OrdemProducaoState,
      action: PayloadAction<(tab: string) => void | Promise<void>>
    ): void {
      const tab = action.payload;

      state.tabFunction = tab;
    },
    setOpList(
      state: OrdemProducaoState,
      action: PayloadAction<OrdemProducaoDTO[]>
    ): void {
      const ordemProducao = action.payload;

      state.opList = [...ordemProducao];
    },
    setGeneralInformation(
      state: OrdemProducaoState,
      action: PayloadAction<{
        materialDescription: string;
        materialFullDescription: string;
        unitMeasurement: string;
        materialCode: string;
        initialDate: Date;
        finalDate: Date;
        batch: string;
        fabricationDate: Date;
        validDate: Date;
        hasQuiz: boolean;
        hasDemands: boolean;
        validBatch: boolean;
        initialFinalReading: boolean;
        materialImage: string;
        changeFabricationDate: boolean;
        unitMeasurementInteger: number;
        unitMeasurementDecimal: number;
        fulfilledFound: boolean;
        fulfilledMessage: string;
        codigoConfigurador: string;
        codigoEspecificacao1: string;
        codigoEspecificacao2: string;
        codigoEspecificacao3: string;
        utilizaGrade: string;
        codigoUnidadeNegocio: string;
        taloes: {
          numeroTalao: number;
          hasDemands: boolean;
        }[];
        hasGrid: string;
        numeration: string;
        lote: string;
        SequenciaPedido: number;
        controlaNumeroSerie: boolean;
        ultimaEtapa: boolean;
        carregouDados: boolean;
        mascaraLote: string;
      }>
    ): void {
      const {
        materialDescription,
        materialFullDescription,
        unitMeasurement,
        materialCode,
        initialDate,
        finalDate,
        batch,
        fabricationDate,
        validDate,
        hasQuiz,
        hasDemands,
        validBatch,
        initialFinalReading,
        materialImage,
        changeFabricationDate,
        unitMeasurementInteger,
        unitMeasurementDecimal,
        fulfilledFound,
        fulfilledMessage,
        codigoConfigurador,
        codigoEspecificacao1,
        codigoEspecificacao2,
        codigoEspecificacao3,
        utilizaGrade,
        codigoUnidadeNegocio,
        taloes,
        hasGrid,
        numeration,
        lote,
        SequenciaPedido,
        controlaNumeroSerie,
        ultimaEtapa,
        carregouDados,
        mascaraLote,
      } = action.payload;

      state.op.hasQuiz = hasQuiz;
      state.op.hasDemands = hasDemands;
      state.op.validBatch = validBatch;
      state.op.initialFinalReading = initialFinalReading;
      state.op.changeFabricationDate = changeFabricationDate;
      state.op.material = {
        code: materialCode,
        description: materialDescription,
        fullDescription: materialFullDescription || materialDescription,
        unitMeasurement,
        image: materialImage,
        integer: unitMeasurementInteger,
        decimal: unitMeasurementDecimal,
        codigoConfigurador,
        codigoEspecificacao1,
        codigoEspecificacao2,
        codigoEspecificacao3,
        utilizaGrade,
        controlaNumeroSerie,
      };
      state.op.ultimaEtapa = ultimaEtapa;
      state.op.codigoUnidadeNegocio = codigoUnidadeNegocio;
      state.op.tabs.general.details = {
        initial: initialDate,
        final: finalDate,
        batch,
        fabrication: fabricationDate,
        valid: validDate,
      };
      state.op.fulfilled = {
        found: fulfilledFound,
        message: fulfilledMessage,
      };
      state.op.taloes = taloes;
      state.op.hasGrid = hasGrid;
      state.op.numeration = numeration;
      state.op.lote = lote;
      state.op.SequenciaPedido = SequenciaPedido;
      state.op.carregouDados = carregouDados;
      state.op.mascaraLote = mascaraLote;
    },
    setFabricationDetails(
      state: OrdemProducaoState,
      action: PayloadAction<{
        fabrication: Date | string | null;
        changeFabricationDate: boolean;
      }>
    ): void {
      const { fabrication, changeFabricationDate } = action.payload;

      state.op.tabs.general.details.fabrication = fabrication;
      state.op.changeFabricationDate = changeFabricationDate;
    },
  },
});

export const { reducer } = slice;

export const {
  updateUser,
  updateCodeOp,
  updateTalao,
  updateQuantity,
  updateDetail,
  updateAllQuantities,
  updateEtiquetasSelect,
  updateQuantitySelect,
  updateParametrizacao,
  updateParametrizacaoConfig,
  updateStep,
  updateInitialStep,
  updateStepLimit,
  updateDemandsComplete,
  updateFulfilled,
  updateModalShow,
  updateModalMessage,
  addInfoTable,
  cancel,
  deleteRecurso,
  setValidationFunction,
  setTabFunction,
  setOpList,
  selectOrdemProducao,
  setGeneralInformation,
  setFabricationDetails,
} = slice.actions;

export const updateExpirationDate =
  (
    codigoOrdemProducao: number,
    talao: number,
    fabricationDate: moment.Moment
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    const date = fabricationDate.format('YYYY-MM-DD');

    const response = await axios.get(
      `/suprimentos/pc/ordemproducao/calculardatavalidade?codigoOrdemProducao=${codigoOrdemProducao}&numeroTalao=${talao}&dataFabricacaoInput=${date}`
    );

    dispatch(slice.actions.updateExpirationDate(response.data));
  };

export const getAllRecursos =
  (op: number, talao: number): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await axios.get(
      `suprimentos/pc/recursoproducao/buscarrecursosordemproducao?codigoOrdemProducao=${op}&numeroTalao=${talao}&$select=TipoProducao,CodigoUnidadeMedida,QuantidadeRecursosUtilizados,QuantidadeProducao,CodigoPCPRecurso,CodigoOcorrencia,DataInicialUtilizacao,DataFinalUtilizacao,HoraFinalUtilizacao,DataFinalMovimentoProducao,HoraFinalMovimentoProducao,CodigoRecurso,Liberacao,HoraInicialUtilizacao,HorasTotaisUtilizacao,CodigoOperador,Sequencia,Codigo&$expand=Recurso($select=Codigo,Descricao),`
    );

    dispatch(slice.actions.updateRecursos(response.data));
  };
