export default [
  'MNPCPESMOV', // MENU PC PES MOVIMENTOS
  'FA755TAM13', // MODF PEDIDOS ASP
  'FA473MEM13', // MOD PEDIDOS ASP
  'GE190EME00', // GE MODULO
  'MNPPROJETO', // ACESSO PORTAL PROJETOS
  'COENTRA_P', // CO ENTRADA PESQUISAR
  'COORDEMC_P', // CO ORDEM COMPRA PESQUISAR
  'MNCOMOV', // MENU CO MOVIMENTOS
  'PN312QWT98', // MOD RECEBIMENTO PRE-NOTA
  'GFLANCTO_P', // GF LANCAMENTO PESQUISAR
  'GFLANCTO_C', // GF LANCAMENTO CRIAR
  'MNGFRELOPE', // MENU GF REL OPERACIONAIS
];
